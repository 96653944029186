<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgImage
			:expirationDate="''"
		></DgImage>

	2022-02-10	improvement: added expirationDate prop, formated as Ymd
	2021-09-24	init

-->

<template>
	<div class="DgImage contentModule" :class="elmClasses"
		 :data-dev-name="'DgImage • feature: ' + _feature + ' • bu: ' + _textPosition + ' • fullspan: ' + isFullspan">
		<div class="DgImage__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgImage__content contentModule__content" :class="contentElmClasses">
				<!--
				<pre>zoomOnHoverScale:{{ zoomOnHoverScale }}</pre>
				<pre>_zoomOnHoverScale:{{ _zoomOnHoverScale }}</pre>
				-->
				<div class="DgImage__imageWrapper">
					<template v-if="!isMediaExpired">
						<div class="relative">
							<MhImage
								class="DgImage__image"
								@setSrc="currentImageSrc = $event"
								:imageObject="image"
							></MhImage>

							<!-- zoom on hover is layouted as overlay -->
							<template v-if="'lenseZoom' === _feature">
								<zoom-on-hover
									class="DgImage__zoomOnHoverWrapper"
									:img-normal="currentImageSrc"
									:img-zoom="imageSrcForZoom"
									:scale="_zoomOnHoverScale"
									:disabled="false"
								></zoom-on-hover>
							</template>

							<!-- feature-buttons: marker -->
							<template v-if="'markers' === _feature">
								<DgButton
									class="DgImage__featureBtn DgImage__featureBtn--marker"
									:class="!marker.image ? 'DgImage__featureBtn--isHidden' : ''"
									v-for="(marker, index) in markers" :key="'m' + index"
									@click.native="onClickFeatureBtn( _feature, {
										image : _.get( marker, 'image' ),
										bu : _.get( marker, 'bu' ),
										copyright : _.get( marker, 'copyright' ),
									} )"
									:style="{
										top : marker.top + '%',
										left : marker.left + '%',
									}"
									:type="'plus'"
									:isCircle="true"
								></DgButton>
							</template>
							<!-- feature-button: superZoom -->
							<DgButton class="DgImage__featureBtn DgImage__featureBtn--superZoom animatedElm"
								v-if="'superZoom' === _feature"
								@click.native="onClickFeatureBtn( _feature )"
								:type="'search'"
								:isCircle="true"
							></DgButton>
							<!-- feature-button: lenseZoom -->
							<DgButton class="DgImage__featureBtn DgImage__featureBtn--lenseZoom animatedElm"
								v-if="'lenseZoom' === _feature"
								@click.native="onClickFeatureBtn( _feature )"
								:type="'search'"
								:isCircle="true"
							></DgButton>
						</div>
					</template>
					<template v-else>
						<DgMediaExpired
							:ratio="image ? _.get( image, 'width') + ':' + _.get( image, 'height') : undefined"
							:type="'image'"
						></DgMediaExpired>
					</template>
				</div>
				<!--
				<pre>currentImageSrc: {{currentImageSrc}}</pre>
				<pre>imageSrcForZoom: {{imageSrcForZoom}}</pre>
				<pre>zoomOnHoverScale: {{zoomOnHoverScale}}</pre>
				-->
				<div class="DgImage__text">
					<div class="DgImage__textInner richText hyphenate">
						<DgBu
							:caption="text"
							:copyright="copyright"
						></DgBu>
					</div>
				</div>
			</div>
		</div>

		<!--
		<pre name="inViewport" v-if="inViewport">{{inViewport}}</pre>
		-->

		<div class="DgImage__debug">
			<pre name="_feature">{{_feature}}</pre>
			<pre name="expirationDate" maxheight>{{expirationDate}}</pre>
			<pre name="isMediaExpired" maxheight>{{isMediaExpired}}</pre>
			<pre name="markers" maxheight>{{markers}}</pre>
			<pre name="currentImageSrc">{{currentImageSrc}}</pre>
			<pre name="imageSrcForZoom">{{imageSrcForZoom}}</pre>
			<pre name="zoomOnHoverScale">{{zoomOnHoverScale}}</pre>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	import inViewportMixin from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/inViewport/v2/inViewport.mixin.js'
	import formatDateToYYYYMMDD from '@/helper/formatDateToYYYYMMDD'
	import DgButton from '@/components/molecules/DgButton.vue'
	import DgBu from '@/components/molecules/DbBu.vue'
	import DgMediaExpired from '@/components/molecules/DgMediaExpired.vue'

	export default {
		name: 'DgImage',
		components: {
			DgButton,
			DgBu,
			MhImage,
			DgMediaExpired,
		},
		mixins: [
			//dgContentModuleMixin,
			inViewportMixin,
		],
		props: {
			image: {
				type     : [Object, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
			textPosition: {
				type     : [String],
				default  : 'below',
				required : false,
			},
			expirationDate: {
				type     : [String],
				default  : '',
				required : false,
			},
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
			copyright: {
				type     : [String],
				default  : '',
				required : false,
			},
			isFullspan: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			feature: {
				type     : [String, Boolean],
				default  : 'none',
				required : false,
			},
			zoomOnHoverScale: {
				type     : [Number, String, Boolean],
				default  : 1.5,
				required : false,
			},
			markers: {
				type     : [Array, Boolean],
				default  : ()=>{ return [] },
				required : false,
			},
		},
		data(){
			return {
				currentImageSrc : null,
			}
		},
		watch: {
			inViewport: {
				handler: function( to, from ) {
					if( to.now ){
						const animateElm = this.$el ? this.$el.querySelector('.animatedElm') : null
						if( animateElm ) animateElm.classList.add('bounceIn')
					}
					else{
						const animateElm = this.$el ? this.$el.querySelector('.animatedElm') : null
						if( animateElm ) animateElm.classList.remove('bounceIn')
					}
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--textPosition-' + this._textPosition)
				if( this.isFullspan ) classes.push( this.$options.name + '--isFullspan' )
				if( this._feature ) classes.push( this.$options.name + '--feature-' + this._feature )

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				//classes.push( 'fontStyle--size-' + this.fontSize )

				return classes
			},
			imageSrcForZoom(){
				return this._.get( this.image, 'sizes.huge' )
			},
			isMediaExpired(){
				let isExpired = false
				const expirationDateInt = this.expirationDate ? parseInt( this.expirationDate ) : undefined
				const todayDateInt = parseInt( formatDateToYYYYMMDD( undefined, '' ) )

				if( expirationDateInt && expirationDateInt <= todayDateInt ) isExpired = true

				/*
				console.log('expirationDateInt:', expirationDateInt)
				console.log('todayDateInt:', todayDateInt)
				*/

				return isExpired
			},
			_zoomOnHoverScale(){
				//const isString = this._.isString( isString )
				let _zoomOnHoverScale = parseFloat( this.zoomOnHoverScale )

				_zoomOnHoverScale = this._.isNaN( _zoomOnHoverScale ) ? 1.5 : _zoomOnHoverScale

				/*
				console.log('---')
				console.log('_zoomOnHoverScale:', typeof(_zoomOnHoverScale), _zoomOnHoverScale)
				*/

				return _zoomOnHoverScale
			},
			_feature(){
				let feature = this.feature

				if( 'lenseZoom' == feature && this.$root.isBelowMd ) feature = ''

				return feature
			},
			_textPosition(){
				let textPosition = this.textPosition

				if( this.$root.isBelowMd ) textPosition = 'below'

				return textPosition
			},
		},
		methods: {
			setCurrentImageSrc( e, doLog = true ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• setCurrentImageSrc()' )
					console.log('e:', e)
					console.groupEnd()
				}
			},
			onClickFeatureBtn( featureName, arg2 = false, doLog = false ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onClickFeatureBtn( featureName )' )
					console.log('featureName:', featureName)
					console.log('arg2:', arg2)
					console.groupEnd()
				}

				if( 'superZoom' === featureName ) EventBus.$emit('doOpenSuperZoom', this.image)
				if( 'markers' === featureName ) EventBus.$emit('doOpenLightbox', arg2)
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgImage { // debug
		[showBorders2] & {
			&__debug {
				display: block;
			}
		}
	}
	.DgImage { // layout
		// grid
		.DgGrid--cols-1 &__content { grid-column: 3 / span 8; }
		.DgGrid--cols-2 &__content { grid-column: 1 / span 6; }

		.DgGrid--cols-1 &--textPosition-left &__content,
		.DgGrid--cols-1 &--textPosition-right &__content {
			.grid;
			.grid--cols-8;
			grid-gap: var(--app-gridGap);
		}
		.DgGrid--cols-1 &--textPosition-left &__imageWrapper {
			grid-column: 3 / span 6;
			grid-row: 1;
		}
		.DgGrid--cols-1 &--textPosition-left &__text {
			grid-column: 1 / span 2;
			grid-row: 1;
		}
		.DgGrid--cols-1 &--textPosition-right &__imageWrapper {
			grid-column: 1 / span 6;
			grid-row: 1;
		}
		.DgGrid--cols-1 &--textPosition-right &__text {
			grid-column: 7 / span 2;
			grid-row: 1;
		}

		.DgGrid--cols-2 &--textPosition-left &__content,
		.DgGrid--cols-2 &--textPosition-right &__content {
			.grid;
			.grid--cols-6;
			grid-gap: var(--app-gridGap);
		}
		.DgGrid--cols-2 &--textPosition-left &__imageWrapper {
			grid-column: 3 / span 4;
			grid-row: 1;
		}
		.DgGrid--cols-2 &--textPosition-left &__text {
			grid-column: 1 / span 2;
			grid-row: 1;
		}
		.DgGrid--cols-2 &--textPosition-right &__imageWrapper {
			grid-column: 1 / span 4;
			grid-row: 1;
		}
		.DgGrid--cols-2 &--textPosition-right &__text {
			grid-column: 5 / span 2;
			grid-row: 1;
		}

		// isFullspan
		.DgGrid--cols-1 &--isFullspan&--textPosition-below {
			max-width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
		.DgGrid--cols-1 &--isFullspan&--textPosition-below &__content {
			grid-column: 1 / span 12;
			display: flex;
			flex-direction: column;
		}
		.DgGrid--cols-1 &--isFullspan&--textPosition-below &__text {
			.grid;
			.grid--cols-12;
			grid-gap: var(--app-gridGap);
			width: 100%;
			max-width: var(--app-content-maxWidth);
			padding-left: var(--app-content-hPadding);
			padding-right: var(--app-content-hPadding);
			align-self: center;
		}
		.DgGrid--cols-1 &--isFullspan&--textPosition-below &__textInner {
			//grid-column: 3 / span 8;
			grid-column: 1 / span 12;
		}

		&__debug { display: none; }
		&__imageWrapper { position: relative; }
		&__zoomOnHoverWrapper { position: absolute !important; top: 0; left: 0; right: 0; bottom: 0; }
		&--feature-lenseZoom &__imageWrapper:hover .DgImage__featureBtn--lenseZoom { display: none; }

		.DgImage__featureBtn--isHidden { display: none; }
		.DgImage__featureBtn--superZoom { position: absolute; right: 1em; bottom: 1em; }
		.DgImage__featureBtn--lenseZoom { position: absolute; right: 1em; bottom: 1em; }
		.DgImage__featureBtn--marker { position: absolute; }

		@media @mq[sm] {
			// grid
			.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-2 &__content { grid-column: 1 / span 12; }

			.DgGrid--cols-1 &--textPosition-below &__text,
			.DgGrid--cols-2 &--textPosition-below &__text {
				//.grid;
				//.grid--cols-12;
			}
			.DgGrid--cols-1 &--textPosition-below &__textInner,
			.DgGrid--cols-2 &--textPosition-below &__textInner {
				//grid-column: 1 / span 12;
			}
			// isFullspan
			.DgGrid--cols-1 &--isFullspan&--textPosition-below &__textInner {
				grid-column: 1 / span 12;
			}
		}
	}
	.DgImage { // styling
		&__zoomOnHoverWrapper.zoomed { background-color: white; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
