var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"DgImage contentModule",class:_vm.elmClasses,attrs:{"data-dev-name":'DgImage • feature: ' + _vm._feature + ' • bu: ' + _vm._textPosition + ' • fullspan: ' + _vm.isFullspan}},[_c('div',{staticClass:"DgImage__inner contentModule__inner",class:_vm.innerElmClasses},[_c('div',{staticClass:"DgImage__content contentModule__content",class:_vm.contentElmClasses},[_c('div',{staticClass:"DgImage__imageWrapper"},[(!_vm.isMediaExpired)?[_c('div',{staticClass:"relative"},[_c('MhImage',{staticClass:"DgImage__image",attrs:{"imageObject":_vm.image},on:{"setSrc":function($event){_vm.currentImageSrc = $event}}}),('lenseZoom' === _vm._feature)?[_c('zoom-on-hover',{staticClass:"DgImage__zoomOnHoverWrapper",attrs:{"img-normal":_vm.currentImageSrc,"img-zoom":_vm.imageSrcForZoom,"scale":_vm._zoomOnHoverScale,"disabled":false}})]:_vm._e(),('markers' === _vm._feature)?_vm._l((_vm.markers),function(marker,index){return _c('DgButton',{key:'m' + index,staticClass:"DgImage__featureBtn DgImage__featureBtn--marker",class:!marker.image ? 'DgImage__featureBtn--isHidden' : '',style:({
									top : marker.top + '%',
									left : marker.left + '%',
								}),attrs:{"type":'plus',"isCircle":true},nativeOn:{"click":function($event){_vm.onClickFeatureBtn( _vm._feature, {
									image : _vm._.get( marker, 'image' ),
									bu : _vm._.get( marker, 'bu' ),
									copyright : _vm._.get( marker, 'copyright' ),
								} )}}})}):_vm._e(),('superZoom' === _vm._feature)?_c('DgButton',{staticClass:"DgImage__featureBtn DgImage__featureBtn--superZoom animatedElm",attrs:{"type":'search',"isCircle":true},nativeOn:{"click":function($event){return _vm.onClickFeatureBtn( _vm._feature )}}}):_vm._e(),('lenseZoom' === _vm._feature)?_c('DgButton',{staticClass:"DgImage__featureBtn DgImage__featureBtn--lenseZoom animatedElm",attrs:{"type":'search',"isCircle":true},nativeOn:{"click":function($event){return _vm.onClickFeatureBtn( _vm._feature )}}}):_vm._e()],2)]:[_c('DgMediaExpired',{attrs:{"ratio":_vm.image ? _vm._.get( _vm.image, 'width') + ':' + _vm._.get( _vm.image, 'height') : undefined,"type":'image'}})]],2),_c('div',{staticClass:"DgImage__text"},[_c('div',{staticClass:"DgImage__textInner richText hyphenate"},[_c('DgBu',{attrs:{"caption":_vm.text,"copyright":_vm.copyright}})],1)])])]),_c('div',{staticClass:"DgImage__debug"},[_c('pre',{attrs:{"name":"_feature"}},[_vm._v(_vm._s(_vm._feature))]),_c('pre',{attrs:{"name":"expirationDate","maxheight":""}},[_vm._v(_vm._s(_vm.expirationDate))]),_c('pre',{attrs:{"name":"isMediaExpired","maxheight":""}},[_vm._v(_vm._s(_vm.isMediaExpired))]),_c('pre',{attrs:{"name":"markers","maxheight":""}},[_vm._v(_vm._s(_vm.markers))]),_c('pre',{attrs:{"name":"currentImageSrc"}},[_vm._v(_vm._s(_vm.currentImageSrc))]),_c('pre',{attrs:{"name":"imageSrcForZoom"}},[_vm._v(_vm._s(_vm.imageSrcForZoom))]),_c('pre',{attrs:{"name":"zoomOnHoverScale"}},[_vm._v(_vm._s(_vm.zoomOnHoverScale))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }