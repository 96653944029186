<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgSection></DgSection>

	2021-09-24	init

-->

<template>
	<div class="DgSection" :class="elmClasses" :style="elmStyles" :feature="feature ? feature : false">
		<div class="DgSection__inner" ref="sectionInner">
			<!--
			<div class="DgSection__rootMarginDev" :style="rootMarginDevElmStyles">
				DgSection__rootMarginDev für IntersectionObserver
			</div>
			<div class="DgSection__debug">
				<div class="DgSection__debugInner">
					DgSection • index: {{ index }} • partPostId: {{ partPostId }}<br/>
					hasArticles: {{ hasArticles ? '🟢' : '🔴' }} <br/>
					count articles: {{ _.size( articles ) }} <br/>
					articles: {{ _.map( articles, 'title' ) }} <br/>
					intersectionObserverIsInViewport: {{ intersectionObserverIsInViewport ? '🟢' : '🔴' }} <br/>
				</div>
			</div>
			-->

			<a class="DgSection__anchor" v-if="isAnchor" :id="'partId-'+partPostId"></a>
			<slot></slot>
		</div>

		<div class="DgSection__debugInfos">
			<strong>DgSection {{index}}</strong><br/>
			<pre name="inViewport">{{inViewport}}</pre>
			<pre name="inViewport">{{inViewportRootMargin}}</pre>
		</div>
		<div class="DgSection__inViewportTrigger"></div>


		<!--
		<pre name="$attrs">{{$attrs}}</pre>
		<pre name="$props">{{$props}}</pre>
		-->
	</div>
</template>

<script>
	import { gsap } from 'gsap'
	import { ScrollTrigger } from "gsap/ScrollTrigger"
	gsap.registerPlugin( ScrollTrigger )

	// this is just an example plugin that allows us to animate a "blur" property
	// like gsap.to(target, {blur:10}) and it'll feed that value to this plugin
	// which will do all the necessary calculations to add/update a blur() value
	// in the CSS "filter" property (in browsers that support it). We wrap it in
	// an iife just so that we can declare some local variables in a private scope
	// at the top.
	const registerGsapBlurPlugin = () => {
		const blurProperty = gsap.utils.checkPrefix("filter"),
				blurExp = /blur\((.+)?px\)/,
				getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];

		gsap.registerPlugin({
			name: "blur",
			get(target) {
			return +(getBlurMatch(target)[1]) || 0;
			},
			init(target, endValue) {
			let data = this,
				filter = gsap.getProperty(target, blurProperty),
				endBlur = "blur(" + endValue + "px)",
				match = getBlurMatch(target)[0],
				index;
			if (filter === "none") {
				filter = "";
			}
			if (match) {
				index = filter.indexOf(match);
				endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
			} else {
				endValue = filter + endBlur;
				filter += filter ? " blur(0px)" : "blur(0px)";
			}
			data.target = target;
			data.interp = gsap.utils.interpolate(filter, endValue);
			},
			render(progress, data) {
			data.target.style[blurProperty] = data.interp(progress);
			}
		});
	//})();
	}
	registerGsapBlurPlugin()

	import EventBus from '@/helper/EventBus.js'
	import inViewportMixin from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/inViewport/v2/inViewport.mixin.js'

	export default {
		name: 'DgSection',
		components: {},
		mixins: [ inViewportMixin ],
		props: {
			isAnchor: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			index: {
				type     : [Number],
				default  : 0,
				required : false,
			},
			feature: {
				type     : [String],
				default  : '',
				required : false,
			},
			hasArticles: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			articles: {
				type     : [Array, Boolean],
				default  : ()=>{ return [] },
				required : false,
			},
			partPostId: {
				type     : [Number, Boolean],
				default  : false,
				required : false,
			},
			bgColor: {
				type     : [String],
				default  : '#FFFFFF',
				required : false,
			},
			textColor: {
				type     : [String],
				default  : undefined,
				required : false,
			},
			isMinHeight100vh: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {
				// Detect the intersection of an element when its top or bottom
				// reaches the vertical center of the viewport
				// https://stackoverflow.com/a/69020644
				//inViewportRootMargin: '-50% 0% -50% 0%',

				// ich habe das inViewport-mixin rausgeworfen.
				// ich war mir einfach nicht sicher, ob es die Fehler mit
				// den fehlenden ArticleButtons verursacht und habe deswegen
				// eine vanilla-js implementierung gebaut
				intersectionObserver: null,
				intersectionObserverRootMargin: '-33% 0% -65% 0%',
				intersectionObserverIsInViewport: false,
			}
		},
		watch: {
			'XXXinViewport.now': function( visible ) {
				if( visible ) this.isInViewport()
				else this.isOutViewport()
 			},
			'intersectionObserverIsInViewport': function( isInViewport ) {
				if( isInViewport ) this.isInViewport()
				else this.isOutViewport()
 			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this._isMinHeight100vh ) classes.push( this.$options.name + '--isFullHeight')
				if( this.feature && this.feature !== 'none' ) classes.push( this.$options.name + '--hasFeature')

				return classes
			},
			elmStyles(){
				let styles = {}

				styles.backgroundColor = this.bgColor
				styles.color = this.textColor

				return styles
			},
			rootMarginDevElmStyles(){
				let styles = {
					position: 'fixed',
					zIndex: 100000,
					pointerEvents: 'auto',
					backgroundColor: 'rgba(255, 0, 0, 0.15)',
					padding: '0.25em',
					fontSize: '8px',
					lineHeight: '1em',
					//opacity: 0.25,
					color: 'red',
				}

				styles.inset = this.intersectionObserverRootMargin.replaceAll( '-', '')

				return styles
			},
			_isMinHeight100vh(){
				let is = this.isMinHeight100vh

				if( this.$root.isBelowMd ) is = false

				return is
			}
		},
		methods: {
			isInViewport( doLog = false ){
				if( doLog ) console.log('isInViewport', this.$options.name, this.partPostId, this.index, '✅')

				//if( this.hasArticles ){
				//}

				this._.forEach( this.articles, (articlePost)=>{
					const _articePost = Object.assign({}, articlePost)
					this.$set( _articePost, 'btnId', this.index + '--' + this.partPostId + '--' + _articePost.id )

					EventBus.$emit('doRegisterArticleButton', _articePost)
				})
				/*
				*/
				this._.forEach( this.articles, (articlePost)=>{
					const _articePost = Object.assign({}, articlePost)
					this.$set( _articePost, 'btnId', this.index + '--' + this.partPostId + '--' + _articePost.id )

					EventBus.$emit('doShowArticleButton', _articePost)
				})
			},
			isOutViewport( doLog = false ){
				if( doLog ) console.log('isOutViewport', this.$options.name, this.partPostId, this.index, '❌')

				this._.forEach( this.articles, (articlePost)=>{
					const _articePost = Object.assign({}, articlePost)
					this.$set( _articePost, 'btnId', this.index + '--' + this.partPostId + '--' + _articePost.id )

					EventBus.$emit('doHideArticleButton', _articePost)
				})
				this._.forEach( this.articles, (articlePost)=>{
					const _articePost = Object.assign({}, articlePost)
					this.$set( _articePost, 'btnId', this.index + '--' + this.partPostId + '--' + _articePost.id )

					EventBus.$emit('doUnregisterArticleButton', _articePost)
				})
				/*
				*/
			},
		},
		created(){},
		mounted(){
			this.$el.style.setProperty( '--section-bgColor', this.bgColor )

			if( 'blurInScaleIn' === this.feature ){
				const triggerElm = this.$el
				const animateElm = this.$refs.sectionInner

				gsap.set( animateElm, {
					opacity: 0,
					scale: 0.5,
					blur: 10,
				})

				const anim = gsap.timeline({
					scrollTrigger: {
						trigger: triggerElm,
						pin: true,
						start: "top top",
						scrub: 0.5,
					},
					defaults: {
						ease: 'none'
					}
				})
				.to( animateElm, {
					opacity: 0.5,
					scale: 1.1,
					transformOrigin: 'center center',
					blur: 6,
					duration: 6,
				}, 0)
				.to( animateElm, {
					opacity: 0.75,
					scale: 1,
					transformOrigin: 'center center',
					blur: 2,
					duration: 4,
				}, 6)
				.to( animateElm, {
					autoAlpha: 1,
					scale: 1,
					transformOrigin: 'center center',
					blur: 0,
					duration: 3,
				}, 10)
			}

			// dieser intersectionObserver triggert die Sichtbarkeit der Article-Buttons
			const el = this.$el
			this.observer2 = new window.IntersectionObserver(([entry]) => {
				if( entry.isIntersecting ){
					console.log('ENTER • ' + this.index + '--' + this.partPostId )
					this.intersectionObserverIsInViewport = true
					return
				}

				this.intersectionObserverIsInViewport = false
				console.log('LEAVE • ' + this.index + '--' + this.partPostId )

				}, {
					//root: null,
					rootMargin: this.intersectionObserverRootMargin,
					//threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
					threshold: 0, // set offset 0.1 means trigger if atleast 10% of element in viewport
				}
			)

			// registiere die ArticleButtons der Section
			/*
			this._.forEach( this.articles, (articlePost)=>{
				EventBus.$emit('doRegisterArticleButton', articlePost)
			})
			*/

			this.observer2.observe( el )
		},
		destroyed(){
			const el = this.$el
			this.observer2.unobserve( el )
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgSection { // debug
		[showBorders2] & {}

		&__debug2 {
			position: fixed;
			inset: 15% 0% 25% 0%;
			z-index: 1000;
			pointer-events: none;
			background-color: fade(red, 15);
		}

		&__debug {
			position: sticky;
			top: 0;
			z-index: 100;
			border-top: 3px solid cyan;
			pointer-events: default;
		}
		&__debugInner {
			//position: relative;
			color: black;
			background-color: cyan;
			display: inline-flex;
			padding: 0.5em;
			max-width: 50vw;
		}
	}
	.DgSection { // layout
		position: relative;

		&--isFullHeight {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		&__inner {
			width: 100%;
		}
		&--isFullHeight &__inner {}

		&__inViewportTrigger { // just a visual helper to see when elm is in/out viewport
			display: none;
			position: fixed;
			top: 50%; right: 0; left: 0;
			height: 3px;
			z-index: 100;
		}
		&__debugInfos {
			display: none;
			position: absolute;
			top: 10px; left: 10px;
		}
	}
	.DgSection { // styling
		&__inViewportTrigger {
			background-color: fade( red, 5 );
		}
		&__debugInfos {
			padding: 0.25em;
			color: black;
			font-size: 12px;
			line-height: 1.2em;
		}
	}

	.DgSection[feature="blurInScaleIn"] {
		min-height: 100vh;
		max-width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
