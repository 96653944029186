/**
 * Converts newlines to <br/>
 *
 * @param str : string
 * @returns string
 */

function nl2br(str = '') {
	const breakTag = '<br />'
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

export default nl2br
