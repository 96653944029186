<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgImages
			:images="[]"
			:layout="'carousel'"
		></DgImages>

	2024-04-14	feature: added layout="kenBurns" and layout="comparisonSlider"
	2021-09-24	init

-->

<template>
	<div class="DgImages contentModule" :class="elmClasses" :data-dev-name="'DgImages • layout=' + layout" v-if="images.length">
		<!--
			<pre name="layout" maxheight>{{layout}}</pre>
			<pre name="$props" maxheight>{{$props}}</pre>
			<pre name="kenBurnsSanitizedImages" maxheight>{{kenBurnsSanitizedImages}}</pre>
			<pre name="comparisonSliderSanitizedImages" maxheight>{{comparisonSliderSanitizedImages}}</pre>
			<pre name="$attrs" maxheight>{{$attrs}}</pre>
			<br/>
			<pre name="slideRatio" maxheight>{{slideRatio}}</pre>
		-->
		<div class="DgImages__inner contentModule__inner" :class="innerElmClasses">
			<template v-if="layout === 'carousel'">
				<div class="DgImages__content contentModule__content" :class="contentElmClasses">
					<VueperSlides class="no-shadow"
						ref="myVueperSlides"
						:transitionSpeed="750"
						:duration="3000"
						:dragging-distance="50"
						:infinite="false"
						:touchable="true"
						:pauseOnHover="false"
						:slideRatio="slideRatio"
						:bullets="false"
						:fixedHeight="true"
						:bulletsOutside="true"
						:disableArrowsOnEdges="true"
						:gap="5"
						@ready="onSliderReady"
						@slide="onSlide">

						<template v-slot:arrow-left>
							<DgButton
								:type="'arrow-left'"
								:isCircle="true"
							></DgButton>
						</template>
						<template v-slot:arrow-right>
							<DgButton
								:type="'arrow-right'"
								:isCircle="true"
							></DgButton>
						</template>

						<VueperSlide v-for="(slide, index) in images" :key="index">
							<template v-slot:content>
								<div class="DgImages__slide">
									<template v-if="!isMediaExpired( slide )">
										<MhImage class="DgImages__slideImage" :imageObject="slide.image" :mode="'contain'"></MhImage>
									</template>
									<template v-else>
										<DgMediaExpired class="DgImages__slideImage"
											:type="'image'"
										></DgMediaExpired>
									</template>
								</div>
							</template>
						</VueperSlide>
					</VueperSlides>
					<div class="DgImages__bullets">
						<div class="DgImages__bullet" v-for="(slide, index) in images" :key="'bullet'+index"
							:class="index === currentActiveIndex ? 'DgImages__bullet--isActive' : 'DgImages__bullet--isInactive'"
							@click="$refs.myVueperSlides.goToSlide(index)">
							{{index}}
						</div>
					</div>
					<div class="DgImages__bus">
						<DgBu class="DgImages__bu"
							:class="index === currentActiveIndex ? 'DgImages__bu--isVisible' : 'DgImages__bu--isHidden'"
							v-for="(slide, index) in images" :key="'bu'+index"
							:caption="slide.text"
							:copyright="slide.copyright"
						></DgBu>
						<!--
						<div class="DgImages__bu fontStyle fontStyle--s"
							:class="index === currentActiveIndex || index === index ? 'DgImages__bu--isVisible' : 'DgImages__bu--isHidden'"
							v-for="(slide, index) in images" :key="'bu'+index">
							<span v-html="slide.text"></span>
							<span style="background-color: yellow" v-html="slide.copyright"></span>
						</div>
						-->
					</div>
				</div>
			</template>
			<template v-if="layout === 'kenBurns'">
				<div class="DgImages__content contentModule__content" :class="contentElmClasses" ref="kenBurnsContainer">
					<MhAspectRatioWrapper class="DgImages__kenBurnsAspectRatioWrapper" :aspectRatio="kenBurnsAspectRatio">
						<ken-burns-carousel class="DgImages__kenBurnsCarousel"
							:images="kenBurnsImagesStr"
							:slide-duration="_.random(12000, 17000)"
							:fade-duration="5000"
						></ken-burns-carousel>
					</MhAspectRatioWrapper>
					<DgBu class="DgImages__bu"
						:caption="_.get( this.images, '[0].text' )"
						:copyright="_.get( this.images, '[0].copyright')"
					></DgBu>
				</div>
			</template>
			<template v-if="layout === 'comparisonSlider'">
				<div class="DgImages__content contentModule__content" :class="contentElmClasses" ref="comparisonSliderContainer">
					<template v-if="comparisonSliderSanitizedImages.length > 1">
						<MhAspectRatioWrapper class="DgImages__comparisonSliderRatioWrapper" :aspectRatio="comparisonSliderAspectRatio">
							<img-comparison-slider class="DgImages__comparisonSlider">
								<img class="DgImages__comparisonSliderImage DgImages__comparisonSliderImage--first" slot="first" width="100%" height="100%" :src="_.get( comparisonSliderSanitizedImages, '[0].bestSource.url')" />
								<img class="DgImages__comparisonSliderImage DgImages__comparisonSliderImage--second" slot="second" width="100%" height="100%" :src="_.get( comparisonSliderSanitizedImages, '[1].bestSource.url')" />
								<div class="DgImages__comparisonSliderHandle" slot="handle">
									<DgButton
										:type="'move-horizontal'"
										:isCircle="true"
									></DgButton>
								</div>
							</img-comparison-slider>
						</MhAspectRatioWrapper>
						<DgBu class="DgImages__bu"
							:caption="_.get( this.images, '[0].text' )"
							:copyright="_.get( this.images, '[0].copyright')"
						></DgBu>
					</template>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import { VueperSlides, VueperSlide } from 'vueperslides'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	import MhAspectRatioWrapper from '@/components/vendor/MhAspectRatioWrapper/v1/MhAspectRatioWrapper.vue'
	import DgButton from '@/components/molecules/DgButton.vue'
	import DgMediaExpired from '@/components/molecules/DgMediaExpired.vue'
	import DgBu from '@/components/molecules/DbBu.vue'
	import formatDateToYYYYMMDD from '@/helper/formatDateToYYYYMMDD'
	import dgContentModuleMixin from '@/mixins/dgContentmodule.mixin'
	import 'ken-burns-carousel'
	import 'img-comparison-slider'

	export default {
		name: 'DgImages',
		components: {
			MhAspectRatioWrapper,
			MhImage,
			DgButton,
			VueperSlides,
			VueperSlide,
			DgBu,
			DgMediaExpired,
		},
		mixins: [ dgContentModuleMixin ],
		props: {
			images: {
				type     : [Array, Boolean],
				default  : ()=>{ return []},
				required : false,
			},
			layout: {
				type     : [String, Boolean],
				default  : 'carousel',
				required : false,
			},
			/*
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
			isFullspan: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			*/
		},
		data(){
			return {
				currentActiveIndex : 0,

				kenBurnsContainer: {
					el : undefined,
					width : undefined,
					height : undefined,
				},
				kenBurnscontainerResizeObserver: null,
				setKenBurnsSanitizedImages: null, // wird mounted() als fn gesetzt
				kenBurnsSanitizedImages: [],

				comparisonSliderContainer: {
					el : undefined,
					width : undefined,
					height : undefined,
				},
				comparisonSliderContainerResizeObserver: null,
				setComparisonSliderSanitizedImages: null, // wird mounted() als fn gesetzt
				comparisonSliderSanitizedImages: [],
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--textPosition-' + this.textPosition)
				if( this.isFullspan ) classes.push( this.$options.name + '--isFullspan' )

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				//classes.push( 'fontStyle--size-' + this.fontSize )

				return classes
			},
			firstImagePost(){
				const imagePost = this._.get( this.images, '[0].image' )

				return imagePost
			},
			secondImagePost(){
				const imagePost = this._.get( this.images, '[1].image' )

				return imagePost
			},
			slideRatio(){
				const firstImagePost = this.firstImagePost
				const width = this._.get( firstImagePost, 'width' )
				const height = this._.get( firstImagePost, 'height' )
				const ratio = width && height ? height / width : 9/16

				return ratio
			},

			kenBurnsAspectRatio(){ // das seitenverhältnis des ersten bildes wird für den ken-burns-carousel wrapper verwendet
				const doLog = false
				const firstImagePost = this.firstImagePost
				const width = this._.get( firstImagePost, 'width' )
				const height = this._.get( firstImagePost, 'height' )
				let aspectRatio = width && height ? width + ':' + height : ''

				if( doLog ) console.groupCollapsed(this.$options.name, '• kenBurnsAspectRatio()')
				if( doLog ) console.log('firstImagePost:', firstImagePost)
				if( doLog ) console.log('aspectRatio:', aspectRatio)
				if( doLog ) console.groupEnd()

				return aspectRatio
			},
			kenBurnsImagesStr(){
				const doLog = false
				const urlsArr = []

				if( doLog ) console.groupCollapsed(this.$options.name, '• kenBurnsImagesStr')

				// collect the best urls
				this._.forEach( this.kenBurnsSanitizedImages, (sanitizedImage)=>{
					const url = this._.get( sanitizedImage, 'bestSource.url')
					const name = this._.get( sanitizedImage, 'bestSource.name')

					if( doLog ) console.log(name, ':', url)

					if( url ) urlsArr.push( url )
				})
				if( doLog ) console.groupEnd()

				return urlsArr.join(' ')

				//return `https://source.unsplash.com/Qh9Swf_8DyA https://source.unsplash.com/O453M2Liufs https://source.unsplash.com/1OtUkD_8svc`
			},

			comparisonSliderAspectRatio(){
				const doLog = false
				const firstImagePost = this.firstImagePost
				const width = this._.get( firstImagePost, 'width' )
				const height = this._.get( firstImagePost, 'height' )
				let aspectRatio = width && height ? width + ':' + height : ''

				if( doLog ) console.groupCollapsed(this.$options.name, '• comparisonSliderAspectRatio()')
				if( doLog ) console.log('firstImagePost:', firstImagePost)
				if( doLog ) console.log('aspectRatio:', aspectRatio)
				if( doLog ) console.groupEnd()

				return aspectRatio
			},
		},
		methods: {
			onSliderReady( e, doLog = false ){},
			onSlide( e, doLog = false ){
				const index = e.currentSlide.index

				this.currentActiveIndex = index
			},
			isMediaExpired( imageObj ){
				let isExpired = false
				const expirationDateInt = imageObj.expirationDate ? parseInt( imageObj.expirationDate ) : undefined
				const todayDateInt = parseInt( formatDateToYYYYMMDD( undefined, '' ) )

				if( expirationDateInt && expirationDateInt <= todayDateInt ) isExpired = true

				/*
				console.log('expirationDateInt:', expirationDateInt)
				console.log('todayDateInt:', todayDateInt)
				*/

				return isExpired
			},
			onKenBurnsContainerResize( e ){
				const rect = e[0].contentRect
				//console.log( e, e[0].contentRect )
				requestAnimationFrame( async () => {
					//console.log( rect.width, rect.height )
					this.kenBurnsContainer.width = rect.width
					this.kenBurnsContainer.height = rect.height
					this.setKenBurnsSanitizedImages()
				})
			},
			onComparisonSliderContainerResize( e ){
				const rect = e[0].contentRect
				//console.log( e, e[0].contentRect )
				requestAnimationFrame( async () => {
					//console.log( rect.width, rect.height )
					this.comparisonSliderContainer.width = rect.width
					this.comparisonSliderContainer.height = rect.height
					this.setComparisonSliderSanitizedImages()
				})
			},
			getSanitizedImageObj( imagePostObj ){
				const newSourcesArr = []

				if( imagePostObj ){
					const sizes = imagePostObj.sizes

					for( let elem in sizes ){
						let key = elem
						let value = sizes[elem]

						// detect if key is a size name, e.g. "thumbnail"
						if( sizes[key+'-width'] ){
							newSourcesArr.push({
								name : key,
								url : value,
								width : sizes[key+'-width'],
								height : sizes[key+'-height'],
							})
						}
					}
				}

				return {
					id : imagePostObj.id,
					bestSource : '',
					sources : newSourcesArr,
				}
			},
			getBestImageSource( imageSources, doLog = false ){
				const width = this.kenBurnsContainer.width
				const height = this.kenBurnsContainer.height
				const matchingSources = this._.filter( imageSources, (source)=>{
					return source.width >= width && source.height >= height
				})
				const bestImageSource = matchingSources.length > 0 ? matchingSources[0] : this._.find( imageSources, { name : 'full'} )

				if( doLog ){
					console.groupCollapsed('getBestImageSource()', imageSources)
					console.log('width:', width)
					console.log('height:', height)
					console.log('imageSources:', imageSources)
					console.log('matchingSources:', matchingSources)
					console.log('bestImageSource:', bestImageSource)
					console.log('test:', this._.find( imageSources, { name : 'full'} ))
					console.groupEnd()
				}

				return bestImageSource
			},
		},
		mounted(){
			if( this.layout === 'kenBurns' && this.$refs.kenBurnsContainer ){
				// attach ResizeObserver to the kenBurnsContainer
				this.kenBurnsContainer.el = this.$refs.kenBurnsContainer
				this.kenBurnsContainerResizeObserver = new ResizeObserver( this.onKenBurnsContainerResize )
				this.kenBurnsContainerResizeObserver.observe( this.kenBurnsContainer.el )

				// after each kenBurnsContainer resize:
				// fill kenBurnsSanitizedImages array with image-objects.
				// each image-object includes the best source url
				// for current kenBurnsContainer dimensions
				this.setKenBurnsSanitizedImages = this._.throttle( ( doLog = false )=>{
					if( doLog ) console.groupCollapsed(this.$options.name, '• setKenBurnsSanitizedImages()')

					const sanitizedImages = []
					//const images = this._.map( this.images, 'image' )

					this._.forEach( this.images, ( imageObj )=>{
						const isMediaExpired = this.isMediaExpired( imageObj )
						const sanitizedImageObj = this.getSanitizedImageObj( this._.get( imageObj, 'image' ) )
						const bestImageSource = this.getBestImageSource( sanitizedImageObj.sources )

						if( doLog ) console.log('imageObj:', imageObj)
						if( doLog ) console.log('isMediaExpired:', isMediaExpired)
						if( doLog ) console.log('sanitizedImageObj:', sanitizedImageObj)
						if( doLog ) console.log('bestImageSource:', bestImageSource)
						if( doLog ) console.log('---')

						sanitizedImageObj.bestSource = bestImageSource
						if( !isMediaExpired ) sanitizedImages.push( sanitizedImageObj )
					})

					this.kenBurnsSanitizedImages = sanitizedImages

					if( doLog ) console.groupEnd()
				}, 1000 )
			}
			if( this.layout === 'comparisonSlider' && this.$refs.comparisonSliderContainer ){
				// attach ResizeObserver to the comparisonSliderContainer
				this.comparisonSliderContainer.el = this.$refs.comparisonSliderContainer
				this.comparisonSliderContainerResizeObserver = new ResizeObserver( this.onComparisonSliderContainerResize )
				this.comparisonSliderContainerResizeObserver.observe( this.comparisonSliderContainer.el )

				// after each comparisonSliderContainer resize:
				// fill comparisonSliderSanitizedImages array with image-objects.
				// each image-object includes the best source url
				// for current comparisonSliderContainer dimensions
				this.setComparisonSliderSanitizedImages = this._.throttle( ( doLog = false )=>{
					if( doLog ) console.groupCollapsed(this.$options.name, '• setComparisonSliderSanitizedImages()')

					const sanitizedImages = []
					//const images = this._.map( this.images, 'image' )

					this._.forEach( this.images, ( imageObj )=>{
						const isMediaExpired = this.isMediaExpired( imageObj )
						const sanitizedImageObj = this.getSanitizedImageObj( this._.get( imageObj, 'image' ) )
						const bestImageSource = this.getBestImageSource( sanitizedImageObj.sources )

						if( doLog ) console.log('imageObj:', imageObj)
						if( doLog ) console.log('isMediaExpired:', isMediaExpired)
						if( doLog ) console.log('sanitizedImageObj:', sanitizedImageObj)
						if( doLog ) console.log('bestImageSource:', bestImageSource)
						if( doLog ) console.log('---')

						sanitizedImageObj.bestSource = bestImageSource
						if( !isMediaExpired ) sanitizedImages.push( sanitizedImageObj )
					})

					this.comparisonSliderSanitizedImages = sanitizedImages

					if( doLog ) console.groupEnd()
				}, 1000 )
			}
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";
	@import "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhVueperSlides/v1/vueperslides.custom.less";

	.DgImages { // debug
		[showBorders2] &__content {
			background-color: fade( red, 25 );
		}
		//.DgImages__bus {}
		//.DgImages__bu { outline: 1px solid red; }
	}
	.DgImages { // layout
		/*
		&__inner {
			.grid;
			grid-gap: var(--app-gridGap);
		}

		.DgGrid--cols-1 &__inner { .grid--cols-12; }
		.DgGrid--cols-2 &__inner { .grid--cols-6; }
		*/

		// grid
		.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
		.DgGrid--cols-2 &__content { grid-column: 1 / span 6; }

		// hSpace
		/*
		.DgGrid--cols-1 & {
			padding-left: var(--app-content-hPadding);
			padding-right: var(--app-content-hPadding);
		}
		.DgGrid--cols-2 .DgColumn--index-0 & {
			padding-left: var(--app-content-hPadding);
		}
		.DgGrid--cols-2 .DgColumn--index-1 & {
			padding-right: var(--app-content-hPadding);
		}
		*/

		// maxWidth
		/*
		.DgGrid--cols-1 & {
			width: 100%;
			max-width: var(--app-content-maxWidth);
			align-self: center;
		}
		.DgGrid--cols-2 .DgColumn--index-0 & {
			width: 100%;
			max-width: calc( ( var(--app-content-maxWidth) - var(--app-gridGap) ) / 2 );
			align-self: flex-end;
		}
		.DgGrid--cols-2 .DgColumn--index-1 & {
			width: 100%;
			max-width: calc( ( var(--app-content-maxWidth) - var(--app-gridGap) ) / 2 );
			align-self: flex-start;
		}
		*/

		// isFullspan
		/*
		.DgGrid--cols-1 &--isFullspan&--textPosition-below {
			max-width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
		.DgGrid--cols-1 &--isFullspan&--textPosition-below &__content {
			grid-column: 1 / span 12;
			display: flex;
			flex-direction: column;
		}
		.DgGrid--cols-1 &--isFullspan&--textPosition-below &__text {
			display: grid;
			.grid--cols-12;
			grid-gap: var(--app-gridGap);
			//padding-left: calc( (100%/2) - (var(--app-gridGap)*0) );
			//width: 100%;
			max-width: var(--app-content-maxWidth);
			padding-left: var(--app-content-hPadding);
			padding-right: var(--app-content-hPadding);
			align-self: center;
		}
		.DgGrid--cols-1 &--isFullspan&--textPosition-below &__textInner {
			grid-column: 3 / span 8;
		}
		*/

		// height
		.vueperslides {
			height: 80vh;
		}

		// slide
		&__slide {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			pointer-events: none;
		}
		&__slideImage {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}

		// bullets
		&__bullets {
			//background-color: fade( green, 20 );
			display: flex;
			justify-content: center;
		}

		// bus
		&__bus {}

		@media @mq[md] {
			// grid
			.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-2 &__content { grid-column: 1 / span 12; }

			.vueperslides { height: 50vh; }
		}

		//&__kenBurnsAspectRatioWrapper { background-color: fade(red, 25); }
		&__kenBurnsCarousel { position: absolute; inset: 0; height: 100%; width: 100%; }

		//&__comparisonSliderRatioWrapper { background-color: fade(red, 25); }
		&__comparisonSlider { position: absolute; inset: 0; height: 100%; width: 100%; display: flex; justify-content: flex-start; }
		&__comparisonSliderImage { display: block; width: 100%; height: 100%; }
		&__comparisonSliderHandle { cursor: move; }
	}
	.DgImages { // styling
		// arrows
		.vueperslides__arrows {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			overflow: hidden;
			pointer-events: none;

			button {
				//position: relative;
				appearance: none;
				background-color: transparent;
				border: 0; outline: 0;
				font-size: inherit;
				line-height: inherit;
				font-family: inherit;
				z-index: 2;
			}
			button > * {
				pointer-events: auto;
			}
		}
		.vueperslides__arrow {
			transition: all 0.25s ease;
			position: absolute;
			top: 0; bottom: 0;
			//transform: translateY(0%);
			padding: 0.5em;
			color: white;
			opacity: 1;

			&--prev { left: 0; transform: translateX(-100%); }
			&--next { right: 0; transform: translateX(100%) }
		}
		&:hover .vueperslides__arrow {
			&--prev { transform: translateX(0%) }
			&--next { transform: translateX(0%) }
		}

		// bullets
		&__bullet {
			height: 0.65em; width: 0.65em;
			overflow: hidden;
			background-color: currentColor;
			border-radius: 50%;
			margin: 1em 0.25em;
			cursor: pointer;
		}
		&__bullet--isActive {
			opacity: 0.75;
		}
		&__bullet--isInactive {
			opacity: 0.2;
		}

		// bu
		&__bu {}
		&__bu--isVisible {
			display: block;
		}
		&__bu--isHidden {
			display: none;
		}

		@media @mq[md] {
			// bullets
			&__bullet {
				height: 0.5em; width: 0.5em;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
