var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"DgLayout",class:_vm.elmClasses},[_c('div',{staticClass:"DgLayout__inner"},[(!_vm.isArticlePost)?_c('DgSection',[_c('DgGrid',[_c('DgColumn',[_c('DgCover',{attrs:{"bgColor":_vm._.get( _vm.post, 'acf.cover.bgColor' ),"titleTypoSvgLandscape":_vm._.get( _vm.post, 'acf.cover.titleTypoSvg--landscape' ),"titleTypoSvgPortrait":_vm._.get( _vm.post, 'acf.cover.titleTypoSvg--portrait' ),"infoTextLeft":_vm._.get( _vm.post, 'acf.cover.infoText--left' ),"infoTextRight":_vm._.get( _vm.post, 'acf.cover.infoText--right' ),"teaserImage":_vm._.get( _vm.post, 'acf.cover.teaserImage' ),"teaserImageVh":_vm._.get( _vm.post, 'acf.cover.teaserImageVh' ),"teaserImageVw":_vm._.get( _vm.post, 'acf.cover.teaserImageVw' )}})],1)],1)],1):_vm._e(),(_vm.isArticlePost)?_c('DgSection',[_c('DgGrid',[_c('DgColumn',[_c('DgArticleHeader',{style:({ backgroundColor : _vm._.get( _vm.post, 'acf.bgColor', '' ) }),attrs:{"overTitle":_vm._.get( _vm.post, 'acf.overTitle' ),"title":_vm._.get( _vm.post, 'acf.title' )}})],1)],1)],1):_vm._e(),_vm._l((_vm._.get( _vm.post, 'acf.sections' )),function(section,i){return _c('DgSection',{key:'serif'+i,attrs:{"index":i,"isAnchor":section.isAnchor,"feature":section.feature,"hasArticles":section.hasArticles,"articles":section.hasArticles ? section.articles : false,"partPostId":section.partPostId,"bgColor":section.bgColor,"textColor":section.textColor,"isMinHeight100vh":section.isMinHeight100vh}},_vm._l((_vm._.get( section, 'grids' )),function(grid,j){return _c('DgGrid',{key:'gr'+j,class:{
					'DgGrid--isFirst' : j === 0,
					'DgGrid--isLast' : j === _vm._.get( section, 'grids' ).length-1
				},attrs:{"countColums":grid.countColums}},_vm._l((_vm._.get( grid, 'columns' )),function(column,k){return _c('DgColumn',{key:'cl'+k,class:{
						'DgColumn--isFirst' : k === 0,
						'DgColumn--isLast' : k === _vm._.get( grid, 'columns' ).length-1
					},attrs:{"index":k}},[_vm._l((column),function(contentModule,l){return [('DgStickyWrapper' === _vm.getComponentName( contentModule ))?[_c('DgStickyWrapper',_vm._b({key:'cm'+l},'DgStickyWrapper',_vm.getComponentBind( contentModule ),false),_vm._l((contentModule.contentModules),function(innerContentModule,l){return _c(_vm.getComponentName( innerContentModule ),_vm._b({key:'cm'+l,tag:"div",style:({ backgroundColor : _vm.isArticlePost ? _vm._.get( _vm.post, 'acf.bgColor', '' ) : '' })},'div',_vm.getComponentBind( innerContentModule ),false))}),1)]:_c(_vm.getComponentName( contentModule ),_vm._b({key:'cm'+l,tag:"div",style:({ backgroundColor : _vm.isArticlePost ? _vm._.get( _vm.post, 'acf.bgColor', '' ) : '' })},'div',_vm.getComponentBind( contentModule ),false))]})],2)}),1)}),1)}),(_vm.isArticlePost)?_c('DgSection',[_c('DgGrid',[_c('DgColumn',[_c('DgSpacer',{style:({ backgroundColor : _vm._.get( _vm.post, 'acf.bgColor', '' ) }),attrs:{"size":'half'}})],1)],1)],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }