<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgArticleButtonsLayer></DgArticleButtonsLayer>

	2021-09-24	init

-->

<template>
	<div class="DgArticleButtonsLayer" :class="elmClasses">
		<div class="DgArticleButtonsLayer__inner">

			<!--
			<div class="DgArticleButtonsLayer__debug">
				<div class="DgArticleButtonsLayer__debugInner">
					<div v-for="(articlePost) in articlePosts" :key="articlePost.btnId + 'sdff'">
						{{ articlePost.btnId }} • {{ articlePost.isVisible ? '🟢' : '🔴' }}
					</div>
				</div>
			</div>
			-->

			<div class="DgArticleButtonsLayer__btnWrapper animated "
				 :class="articlePost.isVisible ? 'isVisible bounceInRight' : 'isHidden bounceOutRight'"
				 :style="{ animationDelay : (index * 100) + 'ms' }"
				 v-for="(articlePost, index) in articlePosts" :key="articlePost.btnId">
				<DgPlusTextButton
					class="animated"
					:class="articlePost.isVisible ? 'isVisible' : 'isHidden'"
					:slug="articlePost.slug"
					:title="articlePost.buttonText"
					:btnId="articlePost.btnId"
				></DgPlusTextButton>
			</div>

			<!--
			<pre name="menubarIsHidden">{{menubarIsHidden}}</pre>
			<pre name="articlePosts">{{articlePosts}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	import DgPlusTextButton from '@/components/molecules/DgPlusTextButton.vue'

	export default {
		name: 'DgArticleButtonsLayer',
		components: {
			DgPlusTextButton,
		},
		mixins: [],
		props: {},
		data(){
			return {
				articlePosts : [],
				menubarIsHidden : undefined,
				stickyHeaderIsHidden : undefined,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.menubarIsHidden ) classes.push( this.$options.name + '--menubarIsHidden')

				if( this.stickyHeaderIsHidden ) classes.push( this.$options.name + '--stickyHeaderIsHidden')

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){
			EventBus.$on('doRegisterArticleButton', ( payload, doLog = true )=>{
				const btnId = this._.get( payload, 'btnId' )
				const _articlePost = {
					btnId : btnId,
					id : payload.id,
					title : payload.title,
					slug : payload.slug,
					buttonText : payload.buttonText,
					isVisible : false,
				}
				const isAlreadyThere = this._.find( this.articlePosts, { btnId : _articlePost.btnId } ) ? true : false

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• on doRegisterArticleButton •', btnId )
					console.log('payload:', payload)
					console.log('_articlePost:', _articlePost)
					console.groupEnd()
				}

				if( !isAlreadyThere ) this.articlePosts.push( _articlePost )
			})
			EventBus.$on('doUnregisterArticleButton', ( payload, doLog = true )=>{
				//this._.remove( this.articlePosts, payload )
				//https://stackoverflow.com/a/43046491

				const btnId = this._.get( payload, 'btnId' )
				//const needleIndex = this.articlePosts.indexOf( payload )
				const needleIndex = this._.findIndex( this.articlePosts, function(o) { return o.btnId == btnId });


				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• on doUnregisterArticleButton •', btnId )
					console.log('payload:', payload)
					console.log('------')
					console.log('articlePosts:', JSON.parse(JSON.stringify(this.articlePosts)) )
					console.log('needleIndex:', needleIndex)
					console.groupEnd()
				}

				this.articlePosts.splice( needleIndex, 1 )
			})
			EventBus.$on('menubarIsHidden', ( payload, doLog = false )=>{
				this.menubarIsHidden = payload
			})
			EventBus.$on('doShowArticleButton', ( payload, doLog = true )=>{
				const btnId = this._.get( payload, 'btnId' )
				const articlePostId = this._.get( payload, 'id' )
				const articlePost = this._.find( this.articlePosts, { btnId : btnId } )
				const isVisible = this._.get( articlePost, 'isVisible' )

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• on doShowArticleButton •', btnId )
					console.log('articlePosts:', this.articlePosts)
					console.log('------')
					console.log('payload:', payload)
					console.log('btnId:', btnId)
					console.log('articlePostId:', articlePostId)
					console.log('articlePost:', articlePost)
					console.log('isVisible:', isVisible)
					console.groupEnd()
				}

				if( articlePost ) articlePost.isVisible = true
			})
			EventBus.$on('doHideArticleButton', ( payload, doLog = true )=>{
				const btnId = this._.get( payload, 'btnId' )
				const articlePostId = this._.get( payload, 'id' )
				const articlePost = this._.find( this.articlePosts, { btnId : btnId } )
				const isVisible = this._.get( articlePost, 'isVisible' )

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• on doHideArticleButton •', btnId )
					console.log('articlePosts:', this.articlePosts)
					console.log('------')
					console.log('payload:', payload)
					console.log('btnId:', btnId)
					console.log('articlePostId:', articlePostId)
					console.log('articlePost:', articlePost)
					console.log('isVisible:', isVisible)
					console.groupEnd()
				}

				if( articlePost ) articlePost.isVisible = false
			})
			EventBus.$on('stickyHeaderIsHidden', ( payload, doLog = false )=>{
				this.stickyHeaderIsHidden = payload
			})
		},
		destroyed(){
   			EventBus.$off('doRegisterArticleButton')
   			EventBus.$off('doShowArticleButton')
   			EventBus.$off('doHideArticleButton')
   			EventBus.$off('menubarIsHidden')
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgArticleButtonsLayer { // debug
		[showBorders2] & {}

		&__debug {
			position: fixed;
			bottom: 3em; right: 3em;
			z-index: 100;
			border-top: 3px solid cyan;
			pointer-events: default;
		}
		&__debugInner {
			//position: relative;
			color: black;
			background-color: cyan;
			padding: 0.5em;
			max-width: 25vw;
		}
	}
	.DgArticleButtonsLayer { // layout
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: 100;
		pointer-events: none;

		&__inner {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
		}

		.DgPlusTextButton {
			pointer-events: auto;
		}
	}
	.DgArticleButtonsLayer { // styling
		&__inner {
			transition: all 0.25s ease;
			padding-top: calc( var(--app-gui-vPadding) + var(--app-button-height) + 1rem );
			padding-right: var(--app-gui-hPadding);
			padding-bottom: var(--app-gui-vPadding);
			padding-left: var(--app-gui-hPadding);
		}
		&--stickyHeaderIsHidden &__inner { padding-top: var(--app-gui-vPadding); }
		&--menubarIsHidden &__inner { padding-top: var(--app-gui-vPadding); }

		&__btnWrapper + &__btnWrapper { margin-top: 0.35em; }

		.DgPlusTextButton {}
		.DgPlusTextButton.isVisible {}
		.DgPlusTextButton.isHidden {}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
