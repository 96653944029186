<template>
	<MhRouterView class="GuideView view" :isContentReady="isContentReady">

		<div class="view__inner">

			<!-- Menü -->
			<DgMainNavLayer v-if="guidePost"
				:menuBgColor="_.get( guidePost, 'acf.menuBgColor' )"
				:menuColor="_.get( guidePost, 'acf.menuColor' )"
				:menuOverlayBgColor="_.get( guidePost, 'acf.menuOverlayBgColor' )"
				:menuOverlayOpacity="_.get( guidePost, 'acf.menuOverlayOpacity' )"

				:menuOverTitle="_.get( guidePost, 'acf.menuOverTitle' )"
				:menuTitle="_.get( guidePost, 'acf.menuTitle' )"
				:chapterLinks="guidePostChapterLinks"
				:additionalLinks="guidePostAdditionalLinks"
			></DgMainNavLayer>

			<!-- Menü-Toggle Button -->
			<MhStickyHeader>
				<div class="GuideView__stickyHeader">
					<DgButton
						:type="'menu'"
						:text="$t('button.menu')"
						@click.native="onClickMenuBtn('menu')"
					></DgButton>
				</div>
			</MhStickyHeader>

			<DgArticleButtonsLayer />

			<DgSuperZoomLayer />

			<DgLightboxLayer />

			<DgReaderLayer :isOpen="readerIsOpen">
				<DgLayout :post="articlePost" :stylesheet="articleStylesheet"></DgLayout>
			</DgReaderLayer>

			<!--
			<DgCover
				:bgColor="_.get( guidePost, 'acf.cover.bgColor' )"
				:titleTypoSvgLandscape="_.get( guidePost, 'acf.cover.titleTypoSvg--landscape' )"
				:titleTypoSvgPortrait="_.get( guidePost, 'acf.cover.titleTypoSvg--portrait' )"
				:infoText="_.get( guidePost, 'acf.cover.infoText' )"
				:teaserImage="_.get( guidePost, 'acf.cover.teaserImage' )"
				:teaserImageMaxWidth="_.get( guidePost, 'acf.cover.teaserImageMaxWidth' )"
				:teaserImageMaxHeight="_.get( guidePost, 'acf.cover.teaserImageMaxHeight' )"
			></DgCover>
			-->

			<DgLayout
				:post="guidePost"
				:stylesheet="guideStylesheet"
			></DgLayout>

			<DgMainFooter v-if="guidePost && _.get( guidePost, 'acf.useFooter' ) != 'none'"
				:menuBgColor="_.get( guidePost, 'acf.menuBgColor' )"
				:menuColor="_.get( guidePost, 'acf.menuColor' )"
				:address="_.get( app, 'acfOptions.footerAddress__' + _.get( guidePost, 'acf.useFooter', 'de' ) )"
				:menu="_.get( app, 'acfOptions.footerGroups__' + _.get( guidePost, 'acf.useFooter', 'de' ) )"
			/>

			<!--
			<pre name="guidePost">{{guidePost}}</pre>
			<pre name="guideStylesheet">{{guideStylesheet}}</pre>
			<pre name="articleStylesheet">{{articleStylesheet}}</pre>
			-->
		</div>

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import currentEnv from '@/helper/getCurrentEnv'

	import { gsap } from 'gsap';
	import { ScrollTrigger } from "gsap/ScrollTrigger";
	gsap.registerPlugin( ScrollTrigger )

	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'
	import MhIcon from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhIcon/v2/MhIcon.vue'
	import MhStickyHeader from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhStickyHeader/v3/MhStickyHeader.vue'

	import normalizeResponseData from '@/helper/dgNormalizeResponseData.js'
	import DgReaderLayer from '@/components/layers/DgReaderLayer.vue'
	import DgArticleButtonsLayer from '@/components/layers/DgArticleButtonsLayer.vue'
	import DgSuperZoomLayer from '@/components/layers/DgSuperZoomLayer.vue'
	import DgLightboxLayer from '@/components/layers/DgLightboxLayer.vue'
	import DgMainNavLayer from '@/components/layers/DgMainNavLayer.vue'
	import DgLayout from '@/components/organisms/DgLayout.vue'
	import DgButton from '@/components/molecules/DgButton.vue'
	import DgMainFooter from '@/components/molecules/DgMainFooter.vue'

	export default {
		name: 'GuideView',
		components: {
			MhRouterView,
			MhIcon,
			MhStickyHeader,
			DgLayout,
			DgReaderLayer,
			DgArticleButtonsLayer,
			DgSuperZoomLayer,
			DgLightboxLayer,
			DgMainNavLayer,
			DgButton,
			//DgCover,
			DgMainFooter,
			//DgEditTopBar,
			//DgEditSideBar,
		},
		mixins: [ restHandler ],
		directives: {},
		props: {},
		data(){
			return {
				isContentReady: false,
				stylesheets : [],
                guidePost: undefined,
				guideStylesheet: undefined,
                articlePost: undefined,
				articleStylesheet: undefined,
			}
		},
		watch: {
			'$route.query.article': {
				handler: function( to, from ) {
					if( to && !this.articlePost ) this.fetchArticleAndOpenReader( this.$route.query.article )
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			readerIsOpen(){
				return this.$route.query.article && this.articlePost ? true : false
			},
			guidePostChapterLinks(){
				const links = []
				const parts = this._.get( this.guidePost, 'acf.parts', [] )
				const validParts = this._.filter( parts, (part)=>{ return part.isEnabled && part.isInMenu })

				//console.log('parts:', parts)
				//console.log('validParts:', validParts)

				validParts.forEach( (item)=>{
					links.push({
						title : item.menuTitle,
						url : '#partId-' + item.postId,
						target : '',
					})
				})

				return links
			},
			guidePostAdditionalLinks(){
				const links = []
				const additionalLinks = this._.map( this._.get( this.guidePost, 'acf.additionalLinks', [] ), 'link' )

				//console.log('additionalLinks:', additionalLinks)

				additionalLinks.forEach( (item)=>{
					//console.log('item:', item)

					links.push({
						title : item.title,
						url : item.url,
						target : item.target != '0' ? item.target : ''
					})
				})

				return links
			},
		},
		methods: {
			fetchAll( finalCallback, doLog = false ){
				let thingsToLoad = 2

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// 1. fetch guide post
				// 2. fetch all includes parts
				// 3. run the callback function
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						slug : this.$route.params.slug,
						postType : 'guide_post',
					},
					callback : (response) => {
						const data = response.data
						const guidePost = this._.get( data, 'result[0]' ) //normalizeResponseData( this._.get( data, 'result[0]' ) )
						const parts = this._.filter( this._.get( guidePost, 'acf.parts' ), (part)=>{ return part.isEnabled })
						const partIds = this._.compact( this._.map( parts, 'postId' ) )

						// groupCollapsed group
						if( doLog ){
							console.groupCollapsed( this.$options.name, '• fetchAll() • fetch guide • callback' )
							console.log('guidePost:', guidePost)
							console.log('parts:', parts)
							console.log('partIds:', partIds)
							console.groupEnd()
						}

						// fetch all includes parts
						this.restHandler__fetch({
							action : 'GET',
							route : '/wp-json/mh/v1/posts',
							params : {
								postIds : partIds.join(','),
								perPage : -1,
							},
							callback : (response) => {
								const data = response.data
								const partPosts = this._.get( data, 'result', [] )

								// groupCollapsed group
								if( doLog ){
									console.groupCollapsed( this.$options.name, '• fetchAll() • fetch parts • callback' )
									console.log('response:', response)
									console.log('partPosts:', partPosts)
									console.groupEnd()
								}

								// set the guidePost sections prop as array
								this._.set( guidePost, 'acf.sections', [] )

								// walk the fetched partPosts
								// extract the sections and push them to guidePost sections
								partPosts.forEach( partPost=>{
									const _partPost = normalizeResponseData( partPost )
									const sections = this._.get( _partPost, 'acf.sections', [] )
									const part = { id : _partPost.id, sections : sections }
									//console.log('_partPost:', _partPost)
									//console.log('sections:', sections)

									this._.forEach(sections, (section, index)=>{
										//console.log('section:', _partPost.id, section)

										if( index === 0 ) section.isAnchor = true
										else section.isAnchor = false

										section.partPostId = _partPost.id

										guidePost.acf.sections.push( section )
									})
								})

								// guidePost is ready
								this.guidePost = guidePost

								runCallback()
							},
						})
					},
				})
				// fetch stylesheets
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'stylesheet_post',
						perPage : -1,
					},
					callback : (response) => {
						const data = response.data
						const results = this._.get( data, 'result', [] )

						// groupCollapsed group
						if( doLog ){
							console.groupCollapsed( this.$options.name, '• fetchAll() • fetch stylesheets • callback' )
							console.log('results:', results)
							console.groupEnd()
						}

						this.stylesheets = results

						runCallback()
					},
				})
			},
			fetchArticleAndOpenReader( slug, callback = ()=>{}, doLog = false ){
				this.articlePost = null

				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						slug : slug
					},
					callback : (response) => {
						const data = response.data
						const post = normalizeResponseData( this._.get( data, 'result[0]' ) )

						// groupCollapsed group
						if( doLog ){
							console.groupCollapsed( this.$options.name, '• fetchArticleAndOpenReader() • callback' )
							console.log('response:', response)
							console.log('post:', post)
							console.groupEnd()
						}

						if( !this.$route.query.article ) this.$router.push({ query: { article : post.slug} })
						this.articlePost = post

						callback()
					},
				})
			},
			setGuideAndArticleStylesheets( doLog = false ){
				const guideStylesheetId = this._.get( this.guidePost, 'acf.guideStylesheet' )
				const articleStylesheetId = this._.get( this.guidePost, 'acf.articleStylesheet' )
				const guideStylesheet = this._.get( this._.find( this.stylesheets, { id : guideStylesheetId } ), 'acf' )
				const articleStylesheet = this._.get( this._.find( this.stylesheets, { id : articleStylesheetId } ), 'acf' )

				if( doLog ){
					console.groupCollapsed( this.$options.name, '• setStylesheetVars()')
					console.log('guideStylesheetId:', guideStylesheetId)
					console.log('articleStylesheetId:', articleStylesheetId)
					console.log('guideStylesheet:', guideStylesheet)
					console.log('articleStylesheet:', articleStylesheet)
					console.groupEnd()
				}

				this.guideStylesheet = guideStylesheet
				this.articleStylesheet = articleStylesheet
			},
			setCssProperties( doLog = false ){
				const guidePost = this.guidePost
				const ctaColor = this._.get( guidePost, 'acf.ctaColor', '#ff5b5b')
				const ctaDarkenColor = this._.get( guidePost, 'acf.ctaDarkenColor', '#ff4242')

				if( doLog ){
					console.groupCollapsed( this.$options.name, '• setCssProperties()' )
					console.log('guidePost:', guidePost)
					console.log('ctaColor:', ctaColor)
					console.log('ctaDarkenColor:', ctaDarkenColor)
					console.groupEnd()
				}

				this.$el.style.setProperty( '--swatch-cta', ctaColor )
				this.$el.style.setProperty( '--swatch-cta-darken', ctaDarkenColor )

				// menü-button
				//this.$el.style.setProperty( '--app-gui-fontSize-s', '8px' )
			},
			onClickMenuBtn( type ){
				if( 'menu' === type ) EventBus.$emit('doOpenMainMenu')
			},
			runGsap( doLog = false ){
				const revealSelectors = [
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgQuote__icon',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgQuote__text',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgQuote__author',
					//'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgText__text p',
					//'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgText__text li',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgText__text',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgText__foldBtn',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgHeadline__lineAbove',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgHeadline__title',
					//'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgChapterHeader__overTitle',
					//'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgChapterHeader__title',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgChapterHeader',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgImage__inner',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgImages__inner',
					//'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DbBu__caption',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgAudioPlayer__inner',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgVideoPlayer__inner',
					'.DgLayout--isGuide .DgSection:not(.DgSection--hasFeature) .DgMainFooter__inner',
					/*
					*/
				]
				const expandSelectors = [
					'.DgChapterHeader__overTitleLine'
				]
				const revealElems = document.querySelectorAll( revealSelectors.join(',') )
				const expandElems = document.querySelectorAll( expandSelectors.join(',') )

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• runGsap()' )
				}

				// reveal animation
				function reveal( elem, direction, action ){
					direction = direction || 1;
					let x = 0,
						y = direction * 100;
					if( elem.classList.contains("gs_reveal_fromLeft") ){
						x = -100;
						y = 0;
					}else if( elem.classList.contains("gs_reveal_fromRight") ){
						x = 100;
						y = 0;
					}
					//elem.style.transform = "translate(" + x + "px, " + y + "px)";
					//elem.style.opacity = "0";
					let first = {
						x: x,
						y: y,
						duration: 1.25,
						autoAlpha: 0,
						ease: "expo",
						overwrite: "auto"
					}
					let second = {
						x: 0,
						y: 0,
						duration: 1.25,
						autoAlpha: 1,
						ease: "expo",
						overwrite: "auto"
					}

					//console.log('reveal', elem, direction, action )

					if( action == 'hide' ){
						//console.log('reveal hide • direction:', direction, elem)
						//gsap.fromTo(elem, second, first)
						//first.y = -y
					}

					if( action == 'show' ){
						//console.log('reveal show • direction:', direction, elem)
						gsap.fromTo(elem, first, second)
					}
				}
				// expand animation
				function expand( elem, direction = 1, action ){
					//elem.style.transform = "translate(" + x + "px, " + y + "px)";
					//elem.style.opacity = "0";
					let first = {
						width:"0%",
					}
					let second = {
						duration: 2,
						delay: 0.25,
						opacity: 1,
						width:"100%",
						ease: "expo",
						overwrite: "auto"
					}

					if( action == 'hide' ){
						//first.x = 0
						//first.y = 0
					}

					if( action == 'show' ){
						gsap.fromTo(elem, first, second)
					}
					if( action == 'hide' ){
						//gsap.fromTo(elem, second, first)
					}
				}

				// set start opacity to 0
				revealElems.forEach(function( elm ) {
					//console.log('revealElem', elm)
					//elm.classList.add('gs_reveal')

					gsap.set( elm, {
						opacity: 0,
						//visibility: 'hidden',
					})
				})

				// init ScrollTrigger for revealElems
				setTimeout(( doLog = false )=>{
					revealElems.forEach(function(elem) {
						//if( doLog ) console.log('elem:', elem)
						//elem.style.opacity = 0

						ScrollTrigger.create({
							trigger: elem,
							//toggleClass: "active",
							once: true,
							start: 'top 90%',
							/*
							markers: {
								startColor: "green",
								endColor: "red",
								fontSize: "14px",
								fontWeight: "bold",
								indent: 0
							},
							immediateRender : true,
							*/
							// scroll down > show elem > y:100 > y:0
							onEnter: ()=>{
								if( doLog ) console.log('onEnter', elem)

								reveal( elem, 1, 'show' )
								/*
								gsap.fromTo(
									elem,
									{
										y: 100,
										duration: 1.25,
										autoAlpha: 0,
										ease: "expo",
										overwrite: "auto"
									},
									{
										y: 0,
										duration: 1.25,
										autoAlpha: 1,
										ease: "expo",
										overwrite: "auto"
									}
								)
								*/
							},
							// scroll down > hide elem > y:0 > y:-100
							onLeave: ()=>{
								if( doLog ) console.log('onLeave', elem)

								reveal( elem, 1, 'hide' )
								/*
								gsap.fromTo(
									elem,
									{
										y: 0,
										duration: 1.25,
										autoAlpha: 1,
										ease: "expo",
										overwrite: "auto"
									},
									{
										y: -100,
										duration: 1.25,
										autoAlpha: 0,
										ease: "expo",
										overwrite: "auto"
									}
								)
								*/
							},
							// scroll up > show elem > y:-100 > y:0
							onEnterBack: ()=>{
								if( doLog ) console.log('onEnterBack', elem)

								reveal( elem, -1, 'show' )
								/*
								gsap.fromTo(
									elem,
									{
										y: -100,
										duration: 1.25,
										autoAlpha: 0,
										ease: "expo",
										overwrite: "auto"
									},
									{
										y: 0,
										duration: 1.25,
										autoAlpha: 1,
										ease: "expo",
										overwrite: "auto"
									}
								)
								*/
							},
							// scroll up > hide elem > y:0 > y:100
							onLeaveBack: ()=>{
								if( doLog ) console.log('onLeaveBack', elem)

								reveal( elem, -1, 'hide' )
								/*
								gsap.fromTo(
									elem,
									{
										y: 0,
										duration: 1.25,
										autoAlpha: 1,
										ease: "expo",
										overwrite: "auto"
									},
									{
										y: 100,
										duration: 1.25,
										autoAlpha: 0,
										ease: "expo",
										overwrite: "auto"
									}
								)
								*/
							},
						})
					})
				}, 10)
				// init ScrollTrigger for expandElems
				setTimeout(()=>{
					expandElems.forEach(function(elem) {
						ScrollTrigger.create({
							trigger: elem,
							once: true,
							start: 'top 90%',
							/*
							markers: {
								//startColor: "green", endColor: "red", fontSize: "16px", fontWeight: "bold", indent: 20
							},
							*/
							//immediateRender : true,
							onEnter: function() { expand(elem, 1, 'show') },
							onEnterBack: function() { expand(elem, -1, 'show') },
							onLeave: function() { expand(elem, 1, 'hide') },
							onLeaveBack: function() { expand(elem, -1, 'hide') },
						})
					})
				}, 10)

				// groupCollapsed group
				if( doLog ){
					console.groupEnd()
				}
			},
			doScrollTop(){
				const hash = this.$route.hash
				const fullPath = this.$route.fullPath
				const fullPathWithoutHash = fullPath.split('#')[0]

				/*
				console.log('hash:', hash ? hash : false)
				console.log('fullPath:', fullPath)
				console.log('fullPathWithoutHash:', fullPathWithoutHash)
				*/

				// remove hash from url if there is one
				if( hash ){
					this.$router.push( fullPathWithoutHash )
				}

				// scroll top
				window.scroll({
					top: 0,
					behavior: 'smooth'
				})
			},
		},
		mounted() {
			// onload: fetch article if its slug is part of the url
			if( this.$route.query.article ) this.fetchArticleAndOpenReader( this.$route.query.article )

			// do scroll top
			EventBus.$on('doScrollTop', payload=>{
				this.doScrollTop()
			})

			// fetch article and open reader
			EventBus.$on('fetchArticleAndOpenReader', payload=>{
				this.fetchArticleAndOpenReader( payload.slug, payload.callback )
			})

			// fetch guide and stylesheets
			this.fetchAll(()=>{
				this.setCssProperties()
				this.setGuideAndArticleStylesheets()

				setTimeout(()=>{
					this.runGsap()
				}, 50)
				setTimeout(()=>{
					this.isContentReady = true
				}, 100)
			})
		},
		destroyed(){
			EventBus.$off('doScrollTop')
			EventBus.$off('fetchArticleAndOpenReader')
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.GuideView {
		//--swatch-cta: darken( green, 5 );
		//--swatch-cta-darken: darken( green, 10 );
	}

	.fontStyle--xxxl {
		font-family: var(--fontStyle-xxxl-fontFamily);
		font-weight: var(--fontStyle-xxxl-fontWeight);

		@media @mq[dt] { font-size: var(--fontStyle-xxxl-dt-fontSize); line-height: var(--fontStyle-xxxl-dt-lineHeight); }
		@media @mq[md] { font-size: var(--fontStyle-xxxl-md-fontSize); line-height: var(--fontStyle-xxxl-md-lineHeight); }
		@media @mq[sm] { font-size: var(--fontStyle-xxxl-sm-fontSize); line-height: var(--fontStyle-xxxl-sm-lineHeight); }
	}
	.fontStyle--xxl {	// 90 / 105
		font-family: var(--fontStyle-xxl-fontFamily);
		font-weight: var(--fontStyle-xxl-fontWeight);

		@media @mq[dt] { font-size: var(--fontStyle-xxl-dt-fontSize); line-height: var(--fontStyle-xxl-dt-lineHeight); }
		@media @mq[md] { font-size: var(--fontStyle-xxl-md-fontSize); line-height: var(--fontStyle-xxl-md-lineHeight); }
		@media @mq[sm] { font-size: var(--fontStyle-xxl-sm-fontSize); line-height: var(--fontStyle-xxl-sm-lineHeight); }
	}
	.fontStyle--xl {	// 50 / 55
		font-family: var(--fontStyle-xl-fontFamily);
		font-weight: var(--fontStyle-xl-fontWeight);

		@media @mq[dt] { font-size: var(--fontStyle-xl-dt-fontSize); line-height: var(--fontStyle-xl-dt-lineHeight); }
		@media @mq[md] { font-size: var(--fontStyle-xl-md-fontSize); line-height: var(--fontStyle-xl-md-lineHeight); }
		@media @mq[sm] { font-size: var(--fontStyle-xl-sm-fontSize); line-height: var(--fontStyle-xl-sm-lineHeight); }
	}
	.fontStyle--l {		// 32 / 42
		font-family: var(--fontStyle-l-fontFamily);
		font-weight: var(--fontStyle-l-fontWeight);

		@media @mq[dt] { font-size: var(--fontStyle-l-dt-fontSize); line-height: var(--fontStyle-l-dt-lineHeight); }
		@media @mq[md] { font-size: var(--fontStyle-l-md-fontSize); line-height: var(--fontStyle-l-md-lineHeight); }
		@media @mq[sm] { font-size: var(--fontStyle-l-sm-fontSize); line-height: var(--fontStyle-l-sm-lineHeight); }
	}
	.fontStyle--m {		// 22 / 32
		font-family: var(--fontStyle-m-fontFamily);
		font-weight: var(--fontStyle-m-fontWeight);

		@media @mq[dt] { font-size: var(--fontStyle-m-dt-fontSize); line-height: var(--fontStyle-m-dt-lineHeight); }
		@media @mq[md] { font-size: var(--fontStyle-m-md-fontSize); line-height: var(--fontStyle-m-md-lineHeight); }
		@media @mq[sm] { font-size: var(--fontStyle-m-sm-fontSize); line-height: var(--fontStyle-m-sm-lineHeight); }
	}
	.fontStyle--s {		// 14 / 18
		font-family: var(--fontStyle-s-fontFamily);
		font-weight: var(--fontStyle-s-fontWeight);

		@media @mq[dt] { font-size: var(--fontStyle-s-dt-fontSize); line-height: var(--fontStyle-s-dt-lineHeight); }
		@media @mq[md] { font-size: var(--fontStyle-s-md-fontSize); line-height: var(--fontStyle-s-md-lineHeight); }
		@media @mq[sm] { font-size: var(--fontStyle-s-sm-fontSize); line-height: var(--fontStyle-s-sm-lineHeight); }
	}

	.DgSection {}
	.DgGrid {}
	.DgColumn {}
	.GuideView__pre {
		display: none;
		[showBorders5] & {
			display: block;
		}
	}
	.DgLayout { // handle click-through
		pointer-events: none;

		.contentModule {
			pointer-events: auto;
		}
	}

    .GuideView { // debug
        [showBorders2] & {}
    }
    .GuideView { // layout
		&__stickyHeader {
			display: flex;
			justify-content: flex-end;
			height: 10em;
			margin-bottom: -10em;
		}
    }
    .GuideView { // styling
		&__stickyHeader {
			padding-left: var(--app-gui-hPadding);
			padding-right: var(--app-gui-hPadding);
			padding-top: var(--app-gui-vPadding);
			pointer-events: none !important;
			//padding-bottom: var(--app-gui-vPadding);
		}
		&__stickyHeader > * { pointer-events: auto; }
		&__stickyHeader .DgButton { margin-left: var(--app-button-gap); }
    }
	.GuideView { // gsap animations
		/*
		.gs_reveal {
			opacity: 0;
			//transition-delay: 0.2s;
			visibility: hidden;
			will-change: transform, opacity;
		}
		*/
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
