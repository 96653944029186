<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgCover></DgCover>

	2021-09-24	init

-->

<template>
	<div class="DgCover contentModule" :class="elmClasses" :style="{ backgroundColor : this.bgColor }" :data-dev-name="'DgCover'">
		<div class="DgCover__inner contentModule__inner">
			<div class="DgCover__screen DgCover__screen--first">
				<div class="DgCover__row DgCover__row--title">
					<div class="DgCover__cell DgCover__cell--titleTypoSvg">
						<div class="DgCover__box--titleTypoSvg-landscape"
							:style="{
								backgroundImage : titleTypoSvgLandscape ? 'url('+ titleTypoSvgLandscape +')' : 'none'
							}"
						></div>
						<div class="DgCover__box--titleTypoSvg-portrait"
							:style="{
								backgroundImage : titleTypoSvgPortrait ? 'url('+ titleTypoSvgPortrait +')' : 'none'
							}"
						></div>
					</div>
				</div>
				<div class="DgCover__row DgCover__row--spacer">
					<div class="DgCover__cell DgCover__cell--spacer"></div>
				</div>
				<div class="DgCover__row DgCover__row--text">
					<!--
					<div class="DgCover__cell DgCover__cell--logo">
						I I I I<br/>
						Kunst- und Kulturstiftung<br/>
						Opelvillen Rüsselsheim<br/>
					</div>
					-->
					<div class="DgCover__cell DgCover__cell--infoTextLeft" v-html="getFormatedText( infoTextLeft )"></div>
					<div class="DgCover__cell DgCover__cell--infoTextRight" v-html="getFormatedText( infoTextRight )"></div>
				</div>

				<!--
				<pre name="titleTypoSvg">{{titleTypoSvg}}</pre>
				<pre name="infoText">{{infoText}}</pre>
				-->
			</div>
			<div class="DgCover__screen DgCover__screen--second">
				<div class="DgCover__row DgCover__row--teaserImage">
					<MhImage class="DgCover__cell DgCover__cell--teaserImage shadow shadow--coverImage"
						:style="{
							height : teaserImageVh + 'vh',
							width : teaserImageVw + 'vw',
						}"
						:imageObject="teaserImage"
						:mode="'contain'"
					></MhImage>
				</div>
				<!--
				<pre name="teaserImage">{{teaserImage}}</pre>
				<pre name="teaserImageMaxWidth">{{teaserImageMaxWidth}}</pre>
				<pre name="teaserImageMaxHeight">{{teaserImageMaxHeight}}</pre>
				-->
			</div>
		</div>

		<!--
		<DgEditComponentInner></DgEditComponentInner>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import nl2br from '@/helper/nl2br.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'DgCover',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			bgColor: {
				type     : [String],
				default  : '',
				required : false,
			},
			titleTypoSvgLandscape: {
				type     : [String, Boolean],
				default  : '',
				required : false,
			},
			titleTypoSvgPortrait: {
				type     : [String, Boolean],
				default  : '',
				required : false,
			},
			infoTextLeft: {
				type     : [String],
				default  : '',
				required : false,
			},
			infoTextRight: {
				type     : [String],
				default  : '',
				required : false,
			},
			teaserImage: {
				type     : [Object, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
			teaserImageVh: {
				type     : [String, Number],
				default  : '50',
				required : false,
			},
			teaserImageVw: {
				type     : [String, Number],
				default  : '50',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			bgColor: {
				handler: function( to, from ) {
					let root = document.documentElement;

					root.style.setProperty( '--app-bgColor', this.bgColor )
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			getFormatedText( text ){
				return nl2br( text )
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgCover { // debug
		[showBorders2] & {
			&__row--text, &__cell--infoTextLeft, &__cell--infoTextRight { background-color: fade( red, 25 ); }
		}
		[showBorders4] & {
			&__screen--first { background-color: fade( green, 25 ); }
			&__screen--second { background-color: fade( red, 25 ); }
			&__screen > * { outline: 1px dotted red; }

			.MhImage { outline: 1px solid red; }
		}
	}
	.DgCover { // vars
		--paddingTop : 2em;
		--paddingBottom : 2em;
		--fontSize-m : 22px;
		--lineHeight-m : 1.2em;

		@media @mq[md] {
			--paddingTop : 4em;
			--fontSize-m : 16px;
			--lineHeight-m : 1.2em;
		}
	}
	.DgCover { // layout
		&__screen {
			height: 100vh;
			display: flex;
			overflow: hidden; // just for nicer dev
			flex-direction: column;
			padding-top: var(--paddingTop);
			padding-bottom: var(--paddingBottom);
			padding-left: var(--app-content-hPadding);
			padding-right: var(--app-content-hPadding);
		}
		&__screen--first { position: sticky; top: 0; }

		&__row--title {	flex-grow: 1; display: flex; }
		&__row--text { display: flex; flex-direction: row; justify-content: flex-end; flex-wrap: wrap; gap: 1.5em; }
		&__row--teaserImage { flex-grow: 1; display: flex; align-items: center; justify-content: center; }

		&__cell--titleTypoSvg { display: flex; flex-grow: 1; }
		&__cell--titleTypoSvg > * { flex-grow: 1; }
		&__cell--teaserImage {}

		@media @mq[dt] {
			.DgCover__box--titleTypoSvg-landscape { display: flex; }
			.DgCover__box--titleTypoSvg-portrait { display: none; }
		}
		@media @mq[md] {
			.DgCover__box--titleTypoSvg-landscape { display: none; }
			.DgCover__box--titleTypoSvg-portrait { display: flex; }
		}
		@media @mq[sm] {
			&__cell--infoTextLeft { width: 100%; }
			&__cell--infoTextRight { width: 100%; }
		}
	}
	.DgCover { // styling
		font-size: var(--fontSize-m);
		line-height: var(--lineHeight-m);

		&__cell--titleTypoSvg > * { background-size: contain; background-repeat: no-repeat; }
		&__cell--spacer { height: 3em; }
		&__cell--infoTextLeft { font-weight: bold; }
		&__cell--infoTextRight { font-weight: bold; }

		@media @mq[sm] {
			//&__cell--infoTextLeft { padding-bottom: 1em; }
			//&__cell--infoTextRight { padding-bottom: 1em; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
